<template>
  <div class="">
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper my-auto">
        <!-- Content area -->
        <div
          class="content justify-content-center align-items-center d-flex"
          style="height: 100vh !important"
        >
          <!-- Container -->
          <div class="flex-fill">
            <!-- Error title -->
            <div class="text-center mb-3">
              <h1 class="error-title">Oops!</h1>
              <h5 ref="message">An error occured, contact support!</h5>
            </div>
            <!-- /error title -->

            <!-- Error content -->
            <div class="row">
              <div class="col-xl-4 offset-xl-4 col-md-8 offset-md-2">
          
                <div class="row">
                  <div class="col-sm-6">
                    <router-link
                      :to="{ name: 'Dashboard' }"
                      class="btn btn-primary btn-block"
                      ><i class="icon-home4 mr-2"></i> Dashboard</router-link
                    >
                  </div>

                  <div class="col-sm-6">
                    <button
                      @click="$router.go(-1)"
                      class="btn btn-light btn-block mt-3 mt-sm-0"
                    >
                      <i class="icon-chevron-left mr-2"></i> Go Back
                    </button>
                  </div>
                </div>
                <!-- /buttons -->
              </div>
            </div>
            <!-- /error wrapper -->
          </div>
          <!-- /container -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>


<script>
import { ref } from 'vue';
export default {
  setup(props) {
    const message = ref(0)
    message.value = props.user
    return { message };
  },
};
</script>